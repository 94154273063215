/*
 * NOTICE
 *
 * This software was produced for the U. S. Government
 * under Contract No. FA8702-19-C-0001, and is subject
 * to the Rights in Noncommercial Computer Software and
 * Noncommercial Computer Software Documentation Clause
 * DFARS 252.227-7014 (FEB 2014)
 *
 * (c) 2000-2021 The MITRE Corporation. All Rights Reserved.
 */
/**
 * Provides an object for creating Bootstrap modal dialogs.
 */
import * as $ from 'jquery';
import 'bootstrap';

type DialogButtonCallback = JQuery.TypeEventHandler<HTMLButtonElement, undefined, HTMLButtonElement, HTMLButtonElement, 'click'>;

interface DialogOptions {
  id?: string;
  large?: boolean;
}

interface ButtonOptions {
  type?: string;
  callback: DialogButtonCallback;
}

let autogenDialogId = 0;

/**
 * Constructor for the dialog box. Keeps the various dialog box components in
 * one object.
 */
export class DialogBox {
  $backdrop: JQuery<HTMLDivElement>;
  $dialog: JQuery<HTMLDivElement>;
  $content: JQuery<HTMLDivElement>;
  $close: JQuery<HTMLButtonElement>;
  $title: JQuery;
  $body: JQuery<HTMLDivElement>;
  $footer: JQuery<HTMLDivElement> = null;
  id: string;
  constructor (html: string, title: string, options?: DialogOptions) {
    this.id = options?.id ?? 'dialog-' + (autogenDialogId++);
    // Create a Bootstrap modal
    this.$backdrop = $('<div/>');
    this.$backdrop.attr({
      id: this.id,
      class: 'modal fade',
      tabindex: '-1',
      role: 'dialog',
      'aria-labeledby': this.id + '-title'
    });
    this.$backdrop.append(this.$dialog = $('<div class="modal-dialog" role="document"/>'));
    if (options?.large) {
      this.$dialog.addClass('modal-lg');
    }
    this.$dialog.append(this.$content = $('<div class="modal-content"/>'));
    const header = $('<div class="modal-header"/>');
    this.$content.append(header);
    header.append(this.$title = $('<h5 class="modal-title"/>'));
    this.$title.attr('id', this.id + '-title');
    this.$title.text(title);
    // Add the close button
    header.append(this.$close = $('<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>'));
    this.$close.on('click', () => { this.destroy(); });
    this.$content.append(this.$body = $('<div class="modal-body"/>'));
    this.$body.append(html);
    $(document.body).append(this.$backdrop);
    this.$backdrop.modal({
      backdrop: 'static', keyboard: false
    });
  }

  getFooter(): JQuery<HTMLDivElement> {
    if (!this.$footer) {
      this.$content.append(this.$footer = $('<div class="modal-footer"/>'));
    }
    return this.$footer;
  }

  addButton(label: string, callbackOrOptions: ButtonOptions | DialogButtonCallback) {
    const options: ButtonOptions = typeof callbackOrOptions === 'object' ? callbackOrOptions : { callback: callbackOrOptions };
    const callback = options.callback;
    const button = $('<button type="button" class="btn"/>');
    if (options.type) {
      button.addClass(options.type);
    }
    button.on('click', callback);
    button.html(label);
    this.getFooter().append(button);
    return button;
  }

  clearButtons() {
    if (this.$footer) {
      this.$footer.remove();
      this.$footer = null;
    }
  }

  destroy() {
    this.$backdrop.modal('hide');
    this.$backdrop.on('hidden.bs.modal', () => {
      this.$backdrop.modal('dispose');
      this.$backdrop.remove();
    });
  }

  setButtonsDisabled(disabled: boolean) {
    this.$footer.find('button').prop('disabled', disabled);
  }

  setText(text: string): void {
    this.$body.text(text);
  }

  setTitle(title: string): void {
    this.$title.text(title);
  }
}

export default DialogBox;