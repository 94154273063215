import Chartkick from 'chartkick';
// Just need to import the implementation library.
import 'chart.js';

// Every chart used displays STE values, so configure the charts for that. (Any chart that isn't used for displaying STE
// will need to manually configure itself, but at present, there are no charts that aren't showing STE on the Y axis.)

Chartkick.setDefaultOptions({
  library: {
    scales: {
      yAxes: [
        {
          ticks: {
            callback: function(n) { return n.toFixed(2); }
          }
        }
      ]
    },
    // TODO: Implement custom tooltips for pages where the chart isn't large enough for them to display on-canvas
    tooltips: {
      callbacks: {
        label: function(data) { return data.yLabel.toFixed(2); }
      }
    }
  }
});