/*
 * NOTICE
 *
 * This software was produced for the U. S. Government
 * under Contract No. FA8702-19-C-0001, and is subject
 * to the Rights in Noncommercial Computer Software and
 * Noncommercial Computer Software Documentation Clause
 * DFARS 252.227-7014 (FEB 2014)
 *
 * (c) 2022 The MITRE Corporation. All Rights Reserved.
 */

// This is set up so it could become its own pack, although right now the
// application pack includes it.

// Import the trumbowyg styles
import './editor.scss';
import trumbowygIcons from 'trumbowyg/dist/ui/icons.svg';

import createRichTextEditor from '../editor';

// Add a loader that will find data-editor="richtext" on textareas once the
// document is loaded
$(function() {
  $('textarea[data-editor="richtext"]').each(function(index, element) {
    createRichTextEditor(element, {
      btns: [
        // ['viewHTML'], TODO: Might make viewHTML available for admins or something?
        ['undo', 'redo'], // Only supported in Blink browsers
        ['limitedFormatting'],
        ['strong', 'em', 'del'],
        ['superscript', 'subscript'],
        // Do not include the link and insert image buttons
        // ['link'],
        // ['insertImage'],
        ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
        ['unorderedList', 'orderedList'],
        // Do not include the horizontal rule (it won't work in Word, at least not right now)
        // ['horizontalRule'],
        ['removeformat'],
        ['fullscreen']
      ],
      // Remove headers from the formatting dropdown
      btnsDef: {
        limitedFormatting: {
          dropdown: ['p', 'blockquote'],
          ico: 'p'
        }
      },
      svgPath: trumbowygIcons
    });
  });
});