// Module for implementing the UI for custom reports

import * as $ from 'jquery';

/**
 * Binds to the HTML within a custom report.
 */
export class CustomReportUI {
  columnOptions: Record<string, HTMLOptionElement[]> = {};
  namedColumnOptions: Record<string, Record<string, HTMLOptionElement>> = {};
  typeRadios: Record<string, HTMLInputElement> = {};
  types: string[] = [];
  $sourceColumnsSelect: JQuery<HTMLSelectElement>;
  $selectedColumnsSelect: JQuery<HTMLSelectElement>;
  $fiscalYears: JQuery<HTMLSelectElement>;
  _selectedType: string;

  constructor(public form: HTMLFormElement) {
    // On creation, find the various columns as optgroups within the column
    // select.
    const $form = $(this.form);
    this.$fiscalYears = $form.find('select[name="custom_report[fiscal_year_ids][]"]') as JQuery<HTMLSelectElement>;
    this.$sourceColumnsSelect = $form.find('select[name="custom_report[source_columns][]"]') as JQuery<HTMLSelectElement>;
    this.$selectedColumnsSelect = $form.find('select[name="custom_report[columns][]"]') as JQuery<HTMLSelectElement>;
    this.$sourceColumnsSelect.children('optgroup').each((_, element) => {
      // Grab all the options out of this and store it in our options
      const $optgroup = $(element);
      const type = $optgroup.attr('label');
      const options = this.columnOptions[type] = $(element).find('option').toArray();
      // Also store them in the named elements
      this.namedColumnOptions[type] = {};
      for (let i = 0; i < options.length; i++) {
        this.namedColumnOptions[type][options[i].value] = options[i];
      }
    });
    let selectedType: string | null = null;
    $form.find('input[type=radio][name="custom_report[type]"]').each((_, element) => {
      const input = element as HTMLInputElement;
      this.typeRadios[input.value] = input;
      this.types.push(input.value);
      if (input.checked) {
        selectedType = input.value;
      }
      $(input).on('change', () => {
        if (input.checked) {
          this.setType(input.value);
        }
      });
    });
    // Bind to buttons
    $form.find('#add-button').on('click', (event) => {
      event.preventDefault();
      this.addColumns();
    });
    $form.find('#remove-button').on('click', (event) => {
      event.preventDefault();
      this.removeColumns();
    });
    $form.on('submit', (event) => {
      // Never submit this form, it won't work as expected
      event.preventDefault();
      this.showReport();
    });
    if (selectedType === null) {
      if (this.types.length < 1) {
        // If there are no types, don't continue.
        return;
      }
      // If there is no selected type, pick the first one
      selectedType = this.types[0];
    }
    this.setType(selectedType);
  }

  get selectedType(): string {
    return this._selectedType;
  }

  /**
   * Adds all selected columns from the source columns list to the report.
   * The added columns will be hidden.
   */
  addColumns() {
    // Grab all the selected columns, and copy them into the selected columns
    // select
    this.$sourceColumnsSelect.find('option:selected').each((_, element) => {
      const option = $(element);
      const newOption = $('<option/>').text(option.text());
      newOption.prop('value', option.prop('value'));
      this.$selectedColumnsSelect.append(newOption)
      option.hide();
    });
  }

  /**
   * Remove any selected columns from the selected columns list.
   */
  removeColumns() {
    this.$selectedColumnsSelect.find('option:selected').each((_, element) => {
      const $element = $(element);
      // Re-show the corresponding element in the selected columns
      const option = this.namedColumnOptions[this._selectedType][$element.prop('value')];
      if (option) {
        $(option).show();
      }
      $element.remove();
    });
  }

  /**
   * At present this just shows the report with the current settings. In the
   * future it will likely become "preview report" or something.
   */
  showReport() {
    // Basically we want to take out the fields we actually care about, create
    // a new form element based on the original, and submit it
    const newForm = $('<form method="POST"/>');
    newForm.attr('action', this.form.action);
    newForm.attr('accept-charset', this.form.getAttribute('accept-charset'));
    // Grab all existing hidden fields and copy thme over (they'fe likely from the framework)
    for (let i = 0; i < this.form.elements.length; i++) {
      const element = this.form.elements.item(i);
      if (element.tagName === 'INPUT') {
        const type = element.getAttribute('type');
        if (type && type.toLowerCase() === 'hidden') {
          // Don't append hidden ActiveModel elements
          if (element.getAttribute('name').substring(0, 13) !== "custom_report") {
            newForm.append($('<input type="hidden"/>').attr('name', element.getAttribute('name')).prop('value', element.getAttribute('value')));
          }
        }
      }
    }
    // Add each selected FY
    this.$fiscalYears.find('option').each((_, element) => {
      if (element.selected)
        newForm.append($('<input type="hidden" name="custom_report[fiscal_year_ids][]"/>').prop('value', element.value));
    });
    newForm.append($('<input type="hidden" name="custom_report[type]"/>').prop('value', this._selectedType));
    // And add each added column, regardless of selection status
    this.$selectedColumnsSelect.find('option').each((_, element) => {
      newForm.append($('<input type="hidden" name="custom_report[columns][]"/>').prop('value', element.value));
    });
    $('body').append(newForm);
    newForm.trigger('submit');
  }

  /**
   * Sets the report to the given report type. This will change the set of
   * visible columns and will remove all columns currently configured for the
   * report.
   * @param type the type to set the report to
   */
  setType(type: string): void {
    // See if this type exists
    const radio = this.typeRadios[type], options = this.columnOptions[type];
    if (radio && options) {
      this._selectedType = type;
      radio.checked = true;
      // Remove all current options from both source and destination
      this.$sourceColumnsSelect.html('');
      this.$selectedColumnsSelect.html('');
      for (let i = 0; i < options.length; i++) {
        // When adding them back in, make sure they're visible
        options[i].style.display = '';
        this.$sourceColumnsSelect.append(options[i]);
      }
    }
  }
}

$(function() {
  // On load look for a form with ID "custom-report" and bind to it if it exists
  $('form#custom-report').each(function(_, element) {
    new CustomReportUI(element as HTMLFormElement);
  });
});