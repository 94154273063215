export function confirmMassAction(select: HTMLSelectElement) {
  let massAction = select.value, count = 0;
  const form = select.form, elements = form.elements;
  for (let i = 0; i < elements.length; i++) {
    // Note this may be wrong, it could be some other form element, but there is
    // no generic "form element" type.
    const element = elements[i] as HTMLInputElement;
    if (element.name === 'users[id][]' && element.checked) {
      count++;
    }
  }
  if (massAction === 'reset')
    massAction = 'reset the password for';
  if (count < 1) {
    alert("You must select at least one user to execute an action on.");
  } else if (confirm("Are you SURE you want to " + massAction + " " + (count === 1 ? "this user" : "these " + count + " users") + "?")) {
    form.submit();
    return true;
  }
  // Because it becomes impossible to re-select this, move the selection back
  // to the first value.
  select.value = select.options[0].value;
  return false;
};
