/*
 * NOTICE
 *
 * This software was produced for the U. S. Government
 * under Contract No. FA8702-19-C-0001, and is subject
 * to the Rights in Noncommercial Computer Software and
 * Noncommercial Computer Software Documentation Clause
 * DFARS 252.227-7014 (FEB 2014)
 *
 * (c) 2000-2021 The MITRE Corporation. All Rights Reserved.
 */
/**
 * Module for showing the "what's new" modal.
 */

 import * as $ from 'jquery';
 import DialogBox from './dialog-box';

 $(function() {
  $('a[data-modal]').on('click', function() {
    try {
      const url = new URL($(this).attr('href'), window.location.toString());
      url.searchParams.append('embed', 'yes');
      $.ajax(url.toString(), {
        dataType: 'html',
        success: function (html) {
          new DialogBox(html, "What's New", { id: 'whatsNew', large: true });
        },
        error: function(xhr, error) {
          alert("Failed to load What's New help: " + error);
          console.log(error);
        }
      });
    } catch (ex) {
      console.log('Unable to get help text:');
      console.log(ex);
    }
    return false;
  });
 });