import * as $ from 'jquery';
import 'trumbowyg';

import { isWordHTML, cleanWordDocFragment } from './clean_word_html';

function handleWordPaste(trumbowyg: Trumbowyg.Trumbowyg, jqueryEvent: JQuery.TriggeredEvent): void {
  // jQuery doesn't handle clipboard events natively, so unwrap it to get the clipboard data
  const pasteEvent = (jqueryEvent.originalEvent as ClipboardEvent);
  if (!pasteEvent.clipboardData) {
    // If there is no clipboard data, just assume this browser isn't supported and ignore the event
    return;
  }
  const pastedData = pasteEvent.clipboardData.getData('text/html');
  if (pastedData.length === 0) {
    // Empty string means no HTML content. Word always drops HTML, so we can
    // safely ignore anything that isn't HTML
    return;
  }
  // Check to see if the pasted data is from Word at all
  if (!isWordHTML(pastedData)) {
    return;
  }
  // If we're here, prevent the default event handling, we're going to clean it
  // up and insert it ourselves.
  jqueryEvent.preventDefault();
  const cleanedPaste = cleanWordDocFragment(pastedData);

  const selection = trumbowyg.doc.getSelection();
  // Assuming it's safe to assume the selection is within the editor
  // We want to replace whatever's selected with the new paste
  selection.deleteFromDocument();
  // Assuming there's still a range (indicating the caret) insert within that
  if (selection.rangeCount > 0) {
    selection.getRangeAt(0).insertNode(cleanedPaste);
  } else {
    // Otherwise, insert it after the focus node
    const parent = selection.focusNode.parentElement, nextNode = selection.focusNode.nextSibling;
    if (nextNode) {
      parent.insertBefore(cleanedPaste, nextNode);
    } else {
      parent.appendChild(cleanedPaste);
    }
  }

  // The selection will now select what we've just inserted (probably) so
  // collapse it to the last node
  selection.collapseToEnd();

  // And sync everything
  trumbowyg.saveRange();
  trumbowyg.syncCode();
}

$.extend(true, $.trumbowyg, {
  plugins: {
    pasteWord: {
      init: function(trumbowyg: Trumbowyg.Trumbowyg): void {
        trumbowyg.pasteHandlers.push(function(pasteEvent) {
          try {
            handleWordPaste(trumbowyg, pasteEvent);
          } catch (ex) {
            console.log('Exception handling paste:');
            console.log(ex);
          }
        });
      }
    }
  }
});