/*
 * NOTICE
 *
 * This software was produced for the U. S. Government
 * under Contract No. FA8702-19-C-0001, and is subject
 * to the Rights in Noncommercial Computer Software and
 * Noncommercial Computer Software Documentation Clause
 * DFARS 252.227-7014 (FEB 2014)
 *
 * (c) 2000-2021 The MITRE Corporation. All Rights Reserved.
 *
 * This module provides a warning for when the user's session is about to timeout.
 */

import * as $ from 'jquery';
import { DialogBox } from './dialog-box';

/**
 * Session timeout. By default we show a warning at 30 minutes, although the
 * actual timeout is 45 minutes.
 */
const SESSION_TIMEOUT_WARNING = 30 * 60 * 1000;
/**
 * Final session timeout. At this time we assume the session has really timed
 * out and then reload the page. (Well, we ping the server to see if it gives us
 * a redirect.)
 */
const SESSION_TIMEOUT_ACTUAL = 45 * 60 * 1000 + 1000;

/**
 * Localization text. This may eventually be loaded from ... somewhere.
 */
const LOCALIZED_TEXT = {
  sessionExpiring: 'Your MRT session is about to expire. If you want to stay logged in, please choose the "Stay Logged In" button, otherwise, you may immediately log out now.',
  sessionExpired: 'Your MRT session has expired. You may log in again to resume working on the site.',
  title: {
    expiring: 'Session Expiring Soon',
    expired: 'Session Expired',
    renewError: 'Unable to restore session'
  },
  stayLoggedIn: 'Stay Logged In',
  renewingSession: 'Renewing the current session...',
  renewError: 'Unable to contact the server to renew the MRT session.',
  logOutNow: 'Log Out Now',
  loggingOut: 'Logging out...',
  logIn: 'Log In',
  reloadingPage: 'Reloading page...'
}

/**
 * Ping path is the path to the "ping" controller. By default we assume it's
 * going to be "/session/ping" but we're told the real path later.
 */
let pingPath = '/session/ping';
let logoutPath = '/sign_out';
/**
 * The root path, used to send the user after their session has in fact expired.
 * By default we assume it's going to be "/" but we're told the real path later.
 */
let rootPath = '/';

/**
 * The dialog object itself.
 */
let dialog: DialogBox | null = null;

/**
 * The warning timeout, cleared if it still exists in certain circumstances.
 */
let warningTimeout: number | null = null;


/**
 * Ping the server. Primarily the intention is to extend the user's session.
 * (This is used by the "Stay Logged In" button.)
 */
function pingServer() {
  // Kill any timeout that still exists while we're waiting.
  if (warningTimeout !== null) {
    clearTimeout(warningTimeout);
    warningTimeout = null;
  }
  $.ajax(pingPath + '.json', {
    success: function(data) {
      if (data['logged_in']) {
        // Success.
        resetWarnings();
      } else {
        // We've logged out
        warnLoggedOut();
      }
    },
    error: function() {
      // On failure, assume we're logged out.
      warnLoggedOut(LOCALIZED_TEXT.renewError, LOCALIZED_TEXT.title.renewError);
    }
  });
}

function logOut(): void {
  $.ajax(logoutPath, {
    method: 'delete',
    error: function() {
      console.log(arguments);
      // If we fail ... ???
      window.location.href = rootPath;
    },
    success: function() {
      // If we've succeeded, bump to the root page, whatever that is.
      window.location.href = rootPath;
    }
  });
}

function warnSessionTimeout(): void {
  dialog = new DialogBox(LOCALIZED_TEXT.sessionExpiring, LOCALIZED_TEXT.title.expiring);
  dialog.addButton(LOCALIZED_TEXT.stayLoggedIn, {
    callback: function(event) {
      event.preventDefault();
      // We need to "ping" the server to ensure that the user remains logged in
      // Change the UI to indicate we're checking...
      dialog.setText(LOCALIZED_TEXT.renewingSession);
      dialog.clearButtons();
      pingServer();
    },
    type: 'btn-primary'
  });
  dialog.addButton(LOCALIZED_TEXT.logOutNow, {
    callback: function(event) {
      event.preventDefault();
      dialog.setText(LOCALIZED_TEXT.loggingOut);
      dialog.clearButtons();
      logOut();
    },
    type: 'btn-danger'
  });
  // Add start another timer after which we assume we're logged out and
  // potentially reload the page.
  warningTimeout = window.setTimeout(warnLoggedOut, SESSION_TIMEOUT_ACTUAL - SESSION_TIMEOUT_WARNING);
}

/**
 * This display a modal box over the page warning that the user has been logged
 * out entirely and only offering the option of reloading the page.
 */
function warnLoggedOut(message?: string, title?: string): void {
  dialog.setText(message ? message : LOCALIZED_TEXT.sessionExpired);
  dialog.setTitle(title ? title : LOCALIZED_TEXT.title.expired);
  dialog.clearButtons();
  dialog.addButton(LOCALIZED_TEXT.logIn, {
    callback: function(event) {
      event.preventDefault();
      dialog.setText(LOCALIZED_TEXT.reloadingPage);
      dialog.clearButtons();
      window.location.reload();
    },
    type: 'btn-primary'
  });
}

/**
 * Removes the dialog (if it exists), clears any existing warning timeouts (if
 * they exist) and restarts the session timeout warning.
 */
function resetWarnings(): void {
  if (dialog) {
    dialog.destroy();
  }
  if (warningTimeout !== null) {
    clearTimeout(warningTimeout);
    warningTimeout = null;
  }
  // And restart the timer.
  warningTimeout = window.setTimeout(warnSessionTimeout, SESSION_TIMEOUT_WARNING);
}

/**
 * Starts the expiration warning.
 * Paths are passed in because conceptually the final paths where the various
 * resources are located aren't known when assets (such as this JavaScript file)
 * are compiled, so they need to be passed in.
 */
export default function startSessionExpirationWarning(ping, logout, root): void {
  pingPath = ping;
  logoutPath = logout;
  rootPath = root;
  warningTimeout = window.setTimeout(warnSessionTimeout, SESSION_TIMEOUT_WARNING);
};
