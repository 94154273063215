import * as $ from 'jquery';
import 'trumbowyg';

// Import our plugin to deal with pastes from Word
import './trumbowyg.pasteword';

/**
 * Replaces a textarea containing HTML text with a rich text editor.
 *
 * Note: this assumes that the content of the textarea is safe. It doesn't do
 * anything to attempt to sanitize the contents of the textarea. (A future
 * version may attempt to scrub the contents to remove elements that may
 * contain JavaScript.)
 * @param textarea the textarea to turn into a rich text editor
 */
export function createRichTextEditor(textarea: HTMLTextAreaElement, options: Trumbowyg.EditorOptions): void {
  // Grab out any options from the editor attribute
  const optionText = textarea.dataset.editorOptions;
  if (optionText) {
    try {
      const optionData = JSON.parse(optionText);
      if (typeof optionData === 'object') {
        // Merge into our options object
        options = $.extend({}, options, optionData);
      }
    } catch (ex) {
      console.log('Unable to parse options');
      console.log(ex);
    }
  }
  // More options are likely to be added in the future but for now this works
  $(textarea).trumbowyg(options);
}

export default createRichTextEditor;