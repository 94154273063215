/*
 * NOTICE
 *
 * This software was produced for the U. S. Government
 * under Contract No. FA8702-19-C-0001, and is subject
 * to the Rights in Noncommercial Computer Software and
 * Noncommercial Computer Software Documentation Clause
 * DFARS 252.227-7014 (FEB 2014)
 *
 * (c) 2000-2021 The MITRE Corporation. All Rights Reserved.
 *
 * Root JavaScript.
 */

import $ from 'jquery';
import {} from 'jquery-ujs';
import 'popper.js';
import 'bootstrap';

// Charts imports Chartkick and the other necessary modules.
import '../charts';

// Import what's new helper code
import '../whats-new';

import * as MRT from '../mrt';
import startSessionExpirationWarning from '../session_timer';
// "Legacy" exports (TODO: move embedded JavaScript out of the page when possible...)
window['MRT'] = MRT;
window['startSessionExpirationWarning'] = startSessionExpirationWarning;

// FIXME: Currently, these need to export everything to the global scope
import * as ste_effects from '../ste_effects';
for (const k in ste_effects) {
  window[k] = ste_effects[k];
}
import * as validations from '../validations';
for (const k in validations) {
  window[k] = validations[k];
}

// "merge" the editor pack (for now)
import './editor';

import makeTablesSortable from '../mrt_tablesorter';

// Currently this is part of the "main pack" (and it will probably remain that way)
import '../custom_report';

import './application.scss';

$(function() {
  // Enable popovers when loaded
  // First, add dl/dd to the whitelist
  $.fn.tooltip.Constructor.Default.whiteList.dl = [];
  $.fn.tooltip.Constructor.Default.whiteList.dd = [];
  // Then enable them
  $('[data-toggle="popover"]').popover({ html: true });
  // Find tables to make sortable
  makeTablesSortable();
});