import 'jquery';

// Common utilities

/**
 * Utility to format a date (not including the time). This formats the date
 * using the standard MRT format. It formats the date in "local time" - that is,
 * it uses the "getField" methods rather than "getUTCField" methods.
 *
 * @param {Date} date the date to format
 */
export function formatDate(date: Date): string {
  // TODO: Format the date using something like moment
  let month = date.getMonth() + 1;
  let monthStr = month < 10 ? '0' + month : month.toString();
  let day = date.getDate();
  let dayStr = day < 10 ? '0' + day : day.toString();
  return date.getFullYear() + '-' + monthStr + '-' + dayStr;
}

/**
 * Format a STE number for display.
 */
export function formatSTE(ste: number): string {
  return ste.toFixed(2);
}

export function enableHelpPopovers(): void {
  $(function() {
    $('[data-help=help-popover]').each(function() {
      var node = $(this);
      // Find the popover
      var popover = node.find('.help-popover').eq(0);
      // Now that we have the popover, bind mouse handlers to the node that
      // requested it.
      node.on('mouseover', function() {
        popover.show();
      });
      node.on('mouseout', function() {
        popover.hide();
      });
    });
  });
}

// Re-exports
export { confirmMassAction } from './users';
export { STERequestUI, setupSteRequestAllocations, setupSteTransferWSTWarning,
  validateSTERequest, validateAllocation } from './ste_requests';
export { Project, certifyWork, confirmProjectTransfer } from './project';
