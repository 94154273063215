/**
 * This module exports a function that uses jQuery to find all tables with
 * data-sortable on them and makes them sortable.
 */

import * as $ from 'jquery';
import 'tablesorter';

export default function makeTablesSortable() {
  $('table[data-sortable]').tablesorter({
    // Currently no options are set.
  });
}