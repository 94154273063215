/**
 * Module for the project edit page.
 */

import * as $ from 'jquery';

import { formatDate } from './mrt';

// Sub namespace
export function Project() { }
export default Project;

var field = "project[certify_work]",
  saveBannerSelector = '#work_certified_on_save',
  workCertifiedBySelector = '#work_certified_by',
  finalizeWorkPackagesSelector = '#finalize_work_packages',
  finalizeWorkPackagesCheckboxSelector = '#project_finalize_work_packages',
  workCertifiedText = null,
  certify = null;

/**
 * Implementation for certifying a project. The project can exist in two
 * states: uncertified or already certified. When already certified, this allows
 * the user to "recertify" the project, which provides additional UI prompts.
 */
export function certifyWork(button: HTMLInputElement, username: string, uncertify: string, showWarning: boolean) {
  // This button is actually a toggle.
  if (button.form[field].value === '1') {
    // Indicates it already was certified and we're "uncertifying" it
    button.form[field].value = '0';
    $(workCertifiedBySelector).hide();
    $(saveBannerSelector).hide();
    $(button).text(certify);
  } else {
    if (certify === null)
      certify = $(button).text();
    if (showWarning) {
      if (!confirm("Caution -- This project is already certified. Do you wish to update/replace the certification?")) {
        return;
      }
    } else {
      $(finalizeWorkPackagesCheckboxSelector).prop('checked',
        confirm("Recommended: Do you wish to finalize all efforts that belong to this project? Once finalized, efforts can no longer be edited without contacting an MRT admin.")
      );
    }
    $(button).text(uncertify);
    // Certify the project.
    if (workCertifiedText === null) {
      workCertifiedText = $(workCertifiedBySelector).text();
    }
    $(workCertifiedBySelector).show().text(
      workCertifiedText.replace(/%\{user\}/, username).replace(/%\{date\}/, formatDate(new Date()))
    );
    $(saveBannerSelector).show();
    $(finalizeWorkPackagesSelector).show();
    button.form[field].value = '1';
  }
};

export function confirmProjectTransfer(form: HTMLFormElement): boolean {
  // Count how many projects are checked, and determine the transfer type (IE
  // does not implement RadioNodeList.value)
  let filledIn = 0, transferType = null;;
  for (let i = 0; i < form.elements.length; i++) {
    const element = form.elements[i] as HTMLInputElement;
    if (element.name === 'projects[id][]' && element.checked) {
      filledIn++;
    } else if (element.name === 'transfer' && element.checked) {
      transferType = element.value;
    }
  }
  if (filledIn < 1) {
    alert("No projects were selected to transfer.");
    return false;
  }
  if (transferType === 'minimal') {
    return confirm('Are you sure you want to transfer a bare minimum of data to the next FY?');
  } else if (transferType === 'complete') {
    return true;
  }
  return false;
}

Project.confirmFinalize = function (form: HTMLFormElement) {
  // Check if this has been certified or will be certified.
  const certified = form.getAttribute('data-certified') == 'true';
  if (!certified) {
    // Check to see if it's *going* to be certified.
    var certifyHidden = form['project[certify_work]'];
    if (certifyHidden && certifyHidden.value != '1') {
      return confirm("WARNING: This project is not certified.\n\n" +
        "Are you sure you want to finalize this PWS before certifying it? It is recommended that you Cancel and certify the PWS (using the Certify button) before finalizing it.\n\n" +
        "Press OK to save WITHOUT certifying and Cancel to continue editing.");
    }
  }
  // If we've fallen through, it can be finalized
  return true;
}

// Rather than import Trumbowyg, just type just enough of the API for our needs
// (If Trumbowyg isn't around, we won't get the object anyway)
interface TrumbowygEditor {
  setDisabled(disabled: boolean): void;
}

function setDisabled(selector: JQuery, disabled: boolean) {
  // This is a bit of a cheat, but basically, if we see the Trumbowyg data
  // property, short-circuit changing the textarea.
  const trumbowyg = selector.data('trumbowyg');
  if (trumbowyg) {
    (trumbowyg as TrumbowygEditor).setDisabled(disabled);
  } else {
    selector.prop('disabled', disabled);
  }
}

$(function () {
  // Bind to anything with data-disableunless
  $('[data-disableunless]').each(function () {
    var nodeToDisable = $(this),
      disableunless = nodeToDisable.data('disableunless'),
      nodeWithValue = $('#' + disableunless);
    var toggle = function () {
      setDisabled(nodeToDisable, nodeWithValue.val() != 'true');
    };
    nodeWithValue.on('change', toggle);
    toggle();
  });
  $('[data-disablewhen]').each(function () {
    var nodeToDisable = $(this),
      disablewhen = nodeToDisable.data('disablewhen'),
      nodeWithValue = $('#' + disablewhen),
      values = nodeToDisable.data('disablevalues').split(',');
    var toggle = function () {
      var val = nodeWithValue.val(), disabled = false;
      for (var i = 0; i < values.length; i++) {
        if (values[i] == val) {
          disabled = true;
          break;
        }
      }
      setDisabled(nodeToDisable, disabled);
    };
    nodeWithValue.on('change', toggle);
    toggle();
  });
});
