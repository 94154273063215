import { formatSTE } from './mrt';

/**
 *
 * @param {HTMLFormElement} form the form to inspect
 * @param {string} match the form fields to match
 * @param {string} totalId the ID of the field to place the total in
 */
export function updateInitFinalTotals(form: HTMLFormElement, match: string, totalId: string) {
  let sum = 0;
  for (let i = 0; i < form.elements.length; i++) {
    let input = form.elements[i] as HTMLInputElement;
    if (input.name.indexOf(match) >= 0) {
      let value = parseFloat(input.value);
      if (!isNaN(value)) {
        sum += value;
      }
    }
  }
  document.getElementById(totalId).innerHTML = formatSTE(sum);
}

// Trap Esc(27), Backspace(8) and Enter(13) -
// Except bksp on text/textareas/password, enter on textarea/submit/link

export function handleKeyPresses(field: HTMLInputElement, event: KeyboardEvent) {
  let keyCode = event.keyCode ? event.keyCode : event.which ? event.which : event.charCode;
  if (keyCode == 13) {
    let i = 0;
    for (; i < field.form.elements.length; i++)
      if (field == field.form.elements[i])
        break;
    i = (i + 1) % field.form.elements.length;
    (field.form.elements[i] as HTMLInputElement).focus();
    return false;
  } else {
    return true;
  }
}

function isValid(id: string, entry: string, validChar: string, min: number, max: number, failureMessage: string): boolean {
  const strlen = entry.length;   // how long is test string
  // Now scan string for illegal characters
  for (let i = 0; i < strlen; i++) {
    if (validChar.indexOf(entry.charAt(i)) < 0) {
      alert(failureMessage);
      const obj = document.getElementById(id) as HTMLInputElement;
      obj.value = "";
      obj.focus();
      return false;
    }
  } // end scan loop

  // range check
  let value = Number(entry);
  if (value < min || value > max) {
    alert('Entry out of range (' + min + ' to ' + max + ').');
    const obj = document.getElementById(id) as HTMLInputElement;
    obj.value = "";
    obj.focus();
    return false;
  }

  //alert('ok!');
  return true;

}

export function isValidXfer(id: string, entry: string): boolean {
  // FIXME: Pretty sure this doesn't match the range set elsewhere
  // FIXME: I'm noting this allows the minus sign but then does a range check
  // that disallows negative numbers anyway.
  return isValid(id, entry, '0123456789-.', 0.01, 50.0, 'Entry must be a number.');
}

export function isValidGroupPrior(id: string, entry: string): boolean {
  return isValid(id, entry, '0123456789.', 0, 20, 'Entry must be a positive number.');
}

export function isValidWorkPacks() {
  const form = document.getElementById('xfer') as HTMLFormElement;
  let slIdx = (form.elements['wp_from_wpnum'] as HTMLSelectElement).selectedIndex;
  const fromObj = (form.elements['wp_from_wpnum'] as HTMLSelectElement).options[slIdx];
  slIdx = (form.elements['wp_to_wpnum'] as HTMLSelectElement).selectedIndex;
  const toObj = (form.elements['wp_to_wpnum'] as HTMLSelectElement).options[slIdx];

  if (fromObj.value == toObj.value) {
    alert("Cannot transfer STE between same efforts: " + fromObj.value + " " + toObj.value);
    toObj.focus();
    return false;
  }
  return true;
}