// MRT-specific JavaScript

export function validate_privnote(): boolean {
  const form = document.getElementById('app_message') as HTMLFormElement;
  const field = form.elements['app_message_message'] as HTMLTextAreaElement;
  //** validate message box has content
  const msg = field.value;
  const len = msg.length;

  if (len < 2) {
    alert('Note cannot be blank or less than 2 characters.');
    field.focus();
    return false;
  }

  if (len > 512) {
    alert('Note cannot exceed 512 chars.');
    field.focus();
    return false;
  }

  //** all good
  return true;
}

export function validate_new_notice_form_fields(): boolean {
  let errFlag = false;
  const form = document.getElementById('new_notice') as HTMLFormElement;
  const field = form.elements['notice_message'] as HTMLTextAreaElement;

  //** validate message box has content
  const msg = field.value;
  const len = msg.length;

  if (len < 5) {
    alert('Message cannot be blank or less than 5 characters.');
    field.focus();
    errFlag = true;
  }

  //** validate recpients is selected
  const recip_sel = form.elements['recip_sel'] as HTMLSelectElement;
  const slIdx = recip_sel.selectedIndex;
  const recipObj = recip_sel.options[slIdx];

  if (recipObj.value == "") {
    alert('Recipient must be selected.');
    recipObj.focus();
    errFlag = true;
  }

  // Return true if no errors, false if errors
  return !errFlag;
}

//****************
//****************
export function validate_ste_xfer_form_fields(): boolean {
  let errFlag = false;

  //** validate Note box has content
  const form = document.getElementById("xfer") as HTMLFormElement;
  const params_message = form.elements['params_message'] as HTMLTextAreaElement;
  const msg = params_message.value;
  const len = msg.length;
  if (len < 5) {
    alert('Note cannot be blank or less than 5 characters.');
    params_message.focus();
    errFlag = true;
  }

  //** validate WP are selected
  const wp_from_wpnum = form.elements['wp_from_wpnum'] as HTMLSelectElement;
  const fromObj = wp_from_wpnum.selectedOptions[0];
  const wp_to_wpnum = form.elements['wp_to_wpnum'] as HTMLSelectElement;
  const toObj = wp_to_wpnum.selectedOptions[0];

  if (fromObj.value == "" || toObj.value == "") {
    alert('To/From efforts must be selected.');
    fromObj.focus();
    errFlag = true;
  }

  //** validate STE value has value
  const params_ste_value = form.elements['params_ste_value'] as HTMLInputElement;
  const ste_val = params_ste_value.value;
  if (ste_val.length == 0) {
    alert('STE Value cannot be blank.');
    params_ste_value.focus();
    errFlag = true;
  }

  // Return true if no errors
  return !errFlag;
}

//****************
//****************
export function askConfirm() {
  // This is currently set via JavaScript embedded within the page itself
  if (window['needToConfirm']) {
    return "You have unsaved changes.";
  }
}