export type Localizer = (name: string, replacements?: Record<string, string>) => string;

/**
 * Namespace for I18n helper functions.
 */
export function createTextDB(text: Record<string, string>): Localizer {
  return function(name: string, replacements?: Record<string, string>): string {
    if (name in text) {
      var result = text[name];
      if (replacements) {
        // Replacements should be an object of key/value pairs. Only the first
        // instance is replaced (at present)
        for (var k in replacements) {
          result = result.replace('%{' + k + '}', replacements[k]);
        }
      }
      return result;
    } else {
      return name;
    }
  };
};