import 'jquery';
import { Localizer, createTextDB } from './i18n';

/**
 * Class for encapsulating the various parts of the STE request UI. The
 * constructor does no actual setup because it may be called before the page has
 * finished loading.
 * @param {object.<String,String>} text
 */
export class STERequestUI {
  _text: Localizer;
  _workPackageSelect: JQuery<HTMLSelectElement>;
  _currentCell: JQuery;
  _newCell: JQuery;
  _changeInput: JQuery;
  _transferCheck: JQuery<HTMLInputElement>;
  _transferSelect: JQuery<HTMLSelectElement>;
  _transferRemaining: JQuery;
  currentAllocation: null | number;
  currentAllocations: Record<string, number>;
  _transferWarning: JQuery | null;
  constructor(text) {
    this._text = createTextDB(text);
    this.currentAllocation = null;
    this._transferWarning = null;
  }

  /**
   * Parse out the current allocations from the work package select.
   */
  _parseCurrentAllocations() {
    // Current allocations comes from the select itself.
    let currentAllocations: Record<string, number> = {};
    $("#ste_request_work_package_id").find("option").each(function () {
      var option = $(this),
        m = /\(STE: (\d+\.\d*)\)$/.exec(option.text());
      if (m)
        currentAllocations[option.val() as string] = parseFloat(m[1]);
    });
    this.currentAllocations = currentAllocations;
  }
  /**
   * Gets the currently selected work package. (Will eventually become a proper
   * getter.)
   */
  selectedWorkPackage() {
    return this._workPackageSelect.val();
  }
  /**
   * Gets the STE change requested. This will either be a number if the request
   * is valid, or the raw string if it is not.
   */
  getSTEChangeRequested(): string | number {
    const val: string = this._changeInput.val() as string;
    if (/^[\+\-]?([0-9]+|[0-9]+\.[0-9]*|[0-9]*\.[0-9]+)$/.test(val)) {
      // Potentially a number in a valid format.
      return parseFloat(val);
    } else if (val.length == 0) {
      return 0;
    }
    return val;
  }
  /**
   * Returns whether or not the user has enabled a transfer
   */
  isTransferChecked() {
    return this._transferCheck.prop('checked');
  }
  /**
   * Perform setup. Requires the page be completely loaded before it will work.
   */
  setup() {
    this._parseCurrentAllocations();
    // Currently the selectors are hardcoded as this requires the existing
    // partial in any case.
    this._workPackageSelect = $('#ste_request_work_package_id');
    this._currentCell = $('#ste_request_current_allocation span');
    this._newCell = $('#ste_request_new_allocation span');
    this._changeInput = $('#ste_request_ste_request');
    // Note: transfer UI may not exist (for example, when editing a request)
    this._transferCheck = $('#ste_request_transfer');
    this._transferSelect = $('#ste_request_transfer_from');
    this._transferRemaining = $('#ste_request_transfer_remaining .ste.remaining');
    this.updateCurrentAllocation(this.selectedWorkPackage());
    this._workPackageSelect.on('change', () => {
      this.updateCurrentAllocation(this.selectedWorkPackage());
    });
    this._changeInput.on('change input', () => {
      try {
        this.updateNewAllocation();
      } catch (ex) {
        // Eat exceptions to prevent errors from making it impossible to use
        // the text field.
        console.log("Exception showing change:");
        console.log(ex);
      }
    });
    this._transferSelect.on('change', () => {
      // Update the transfer allocation
      this.updateTransferAllocation();
    });
  }
  isTransferUIPresent() {
    return this._transferCheck && this._transferCheck.length > 0;
  }
  /**
   * Update the current allocation shown for the given work package.
   * @param {String} workPackage
   */
  updateCurrentAllocation(workPackage) {
    if (workPackage in this.currentAllocations) {
      this.currentAllocation = this.currentAllocations[workPackage];
      this._currentCell.text(this.currentAllocation.toFixed(2));
    } else {
      this.currentAllocation = null;
      this._currentCell.text('Unknown');
    }
    // In either case update the new allocation
    this.updateNewAllocation();
  }
  /**
   * Updates the new allocation display.
   */
  updateNewAllocation() {
    if (this.currentAllocation == null) {
      this._newCell.text('Unknown');
      // This is "safe" even if there is no transfer UI: it will just be a no-op
      this._transferRemaining.text('Unknown');
      return;
    }
    let change = this.getSTEChangeRequested();
    if (typeof change != 'number') {
      this._newCell.text(this._text('invalid', { ste_request: change }));
    } else {
      this._newCell.text(this._text('request', { ste_request: (this.currentAllocation + change).toFixed(2) }));
      // If this makes it negative, show a warning message.
      if ((this.currentAllocation + change) < 0) {
        this._newCell.append($('<div class="warning"/>').text(this._text('negative')));
      }
      // If there is a transfer, also update the transfer UI.
      if (this.isTransferUIPresent()) {
        this.updateTransferAllocation(change);
      }
    }
  }
  updateTransferAllocation(change?: number) {
    if (arguments.length < 1) {
      const requestedChange = this.getSTEChangeRequested();
      if (typeof requestedChange === 'number') {
        change = requestedChange;
      } else {
        this._transferRemaining.text(this._text('invalid', { ste_request: requestedChange }));
        return;
      }
    }
    // Check to make sure the request is positive
    let negativeChange = change < 0;
    let transferWarning: string[] = [];
    if (negativeChange) {
      // If the change itself is negative, disallow transfers.
      transferWarning.push(this._text('negativeTransfer'));
      if (this.isTransferChecked()) {
        transferWarning.push(this._text('negativeTransferIgnored'));
      }
    }
    this._transferCheck.prop('disabled', negativeChange);
    this._transferSelect.prop('disabled', negativeChange || (!this.isTransferChecked()));
    let fromWP = this._transferSelect.val() as string;
    if (fromWP == '') {
      this._transferRemaining.text('No effort selected');
    } else if (fromWP == this.selectedWorkPackage()) {
      transferWarning.push(this._text('invalidTransferSelf'));
    } else if (fromWP in this.currentAllocations) {
      var remainingAllocation = this.currentAllocations[fromWP] - change;
      this._transferRemaining.text(remainingAllocation.toFixed(2));
      if (remainingAllocation < 0) {
        transferWarning.push(this._text('negativeTransferBalance', { allocated: this.currentAllocations[fromWP].toFixed(2) }));
      }
    } else {
      this._transferRemaining.text('Unknown');
    }
    this._setTransferWarning(transferWarning.length == 0 ? null : transferWarning.join(' '));
  }
  _setTransferWarning(text) {
    if (text) {
      if (this._transferWarning === null) {
        this._transferWarning = $('<div class="warning"/>');
        this._transferSelect.after(this._transferWarning);
      }
      this._transferWarning.text(text);
      this._transferWarning.show();
    } else {
      // If the warning exists, hide it
      if (this._transferWarning !== null)
        this._transferWarning.hide();
    }
  }
  _showTransferWarning(show) {
    if (this._transferWarning !== null) {
      this._transferWarning.toggle(show);
    }
  }
}

/**
 * Sets up the STE Request so that the given placeholder selector is given the
 * text based on current allocations given to this method.
 *
 * @param {Object.<string,string>} text localized strings to use for displaying
 *     messages
 */
export function setupSteRequestAllocations(text) {
  $(function () {
    var requestUI = new STERequestUI(text);
    requestUI.setup();
  });
}

export function setupSteTransferWSTWarning(data: {
  project: string | null,
  workPackages: Record<string, string>,
  workSupportTypes: Record<string, string>
  warning: string
}) {
  // Data contains only the information we need: the project WST, any work
  // packages that override that, and localized names.
  const projectWST = data['project'],
    workPackageWSTs = data['workPackages'],
    wstNames = data['workSupportTypes'],
    warningText = data['warning'];
  const fromSelect = $('#ste_request_transfer_from'),
    toSelect = $('#ste_request_work_package_id'),
    transferCheck = $('#ste_request_transfer');
  let warningElement = null;
  function updateWSTWarning() {
    var transferEnabled = transferCheck.prop('checked');
    // Disable the transfer select if not checked
    fromSelect.prop('disabled', !transferEnabled);
    // Preemptively hide the warning if it exists - assume browsers won't
    // repaint until we're done.
    if (warningElement !== null) {
      warningElement.hide();
    }
    if (!transferEnabled) {
      // If there is no transfer, no need to do the rest
      return;
    }
    let fromWP = fromSelect.val() as string,
      toWP = toSelect.val() as string;
    if (toWP == '') {
      // If to wasn't selected, do nothing for now
      return;
    }
    var fromWST = projectWST, toWST = projectWST;
    if (fromWP in workPackageWSTs) {
      fromWST = workPackageWSTs[fromWP];
    }
    if (toWP in workPackageWSTs) {
      toWST = workPackageWSTs[toWP];
    }
    if (fromWST != toWST) {
      if (warningElement === null) {
        // Create it!
        warningElement = $('<div class="warning"></div>');
        // Insert it after the select
        toSelect.after(warningElement);
      } else {
        warningElement.show();
      }
      warningElement.text(warningText
        .replace('%{from_type}', wstNames[fromWST])
        .replace('%{to_type}', wstNames[toWST]));
    }
  }
  transferCheck.on('change', updateWSTWarning);
  fromSelect.on('change', updateWSTWarning);
  toSelect.on('change', updateWSTWarning);
  // And fire one on load for good measure.
  $(updateWSTWarning);
}

export function validateSTERequest() {
  // FIXME: At some point a lot of these validations can probably be somewhat
  // automated via HTML5 forms. For now just make sure that the justification
  // and STE request is filled out and that there isn't a request to transfer
  // to/from ourselves.
  let val = $('#ste_request_ste_request').val() as string;
  if (/^\s*$/.test(val)) {
    alert("STE request must be filled in.");
    return false;
  }
  if (!/^[\+\-]?(\.?[0-9]+|[0-9]+\.[0-9]*)$/.test(val)) {
    alert("Invalid STE request \"" + val + "\": must be a number.");
    return false;
  }
  val = $('#ste_request_justification').val() as string;
  if (/^\s*$/.test(val)) {
    alert("Justification must be filled out.");
    return false;
  }
  if ($('#ste_request_transfer').prop('checked')) {
    if ($('#ste_request_work_package_id').val() == $('#ste_request_transfer_from').val()) {
      alert("Cannot create a STE request to transfer STE to and from the same effort.");
      return false;
    }
    if (Number($('#ste_request_ste_request').val()) < 0) {
      return confirm("You cannot transfer negative STE from another effort. Your transfer will be ignored.");
    }
  }
  return true;
}

/**
 * Validates a STE allocation. Allows the STE allocation to have a specified
 * minimum and maximum.
 */
export function validateAllocation(text: HTMLInputElement, minimum: number | boolean, maximum?: number) {
  if (arguments.length == 2 && minimum === false) {
    minimum = 0;
    maximum = 200;
  } else {
    minimum = -50;
    maximum = 99;
  }
  const entry = text.value;
  const m = /^\s*(-?)\s*(\d+\.\d*|\.?\d+)\s*$/.exec(entry);
  let valid = false, alloc: number;
  if (m) {
    // Potentially valid
    alloc = Number(m[2]);
    if (m[1] === '-') {
      alloc = -alloc;
    }
    if (alloc >= minimum && alloc <= maximum) {
      // For now always warn if the allocation is "high"
      if (alloc >= 100) {
        alert('Warning: allocation appears high. Did you make a typo?');
      }
      valid = true;
    } else {
      alert('Invalid allocation ' + alloc + ': outside of range [' + minimum + '-' + maximum + '].');
    }
  } else if (entry != '') {
    alert('Invalid allocation "' + entry + '": not a number.');
  }
  // Update the field with the updated result. Currently blank it if given an invalid number.
  text.value = valid ? alloc.toFixed(2) : '';
  return valid;
}
